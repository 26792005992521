import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "cloudUrlInput",
    "documentInput",
    "cloudUrlRadio",
    "documentRadio",
    "container",
  ];

  toggleInputs(event) {
    const cloudUrlInput = this.cloudUrlInputTarget;
    const documentInput = this.documentInputTarget;
    const cloudUrlRadio = this.cloudUrlRadioTarget;
    const documentRadio = this.documentRadioTarget;
    const container = this.containerTarget;

    if (event.type === "focus") {
      if (event.target === cloudUrlInput) {
        documentInput.value = "";
        cloudUrlRadio.checked = true;
        container.classList.add("option-1");
        container.classList.remove("option-2");
      } else if (event.target === documentInput) {
        cloudUrlInput.value = "";
        documentRadio.checked = true;
        container.classList.add("option-2");
        container.classList.remove("option-1");
      }
    }
    // else if (event.type === "blur") {
    //   debugger
    //   if (cloudUrlInput.value) {
    //     cloudUrlRadio.checked = true;
    //     container.classList.add("option-1");
    //     container.classList.remove("option-2");
    //   } else if (documentInput.value) {
    //     documentRadio.checked = true;
    //     container.classList.add("option-2");
    //     container.classList.remove("option-1");
    //   }
    // }
  }
}
